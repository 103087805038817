import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import {
  AutoFillStatus,
  IntakeForm,
  LoadResponses,
  useAnalytics,
} from "@smart/bridge-intake-components-dom";
import { track } from "@smart/bridge-metrics-dom";
import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import { useAsync } from "@smart/itops-hooks-dom";
import { delay } from "@smart/itops-utils-basic";

import {
  intakeApi,
  IntakeApiAIUserFeedback,
  IntakeApiForm,
  IntakeApiSubmission,
  IntakeApiTeam,
  useAddressLookup,
  useFileLookup,
} from "../../hooks";

const AI_FILL_POLLING_INTERVAL = 2000;

export type SubmissionFormProps = {
  team: IntakeApiTeam;
  form: IntakeApiForm;
  submission: IntakeApiSubmission;
  aiUserFeedback: IntakeApiAIUserFeedback | undefined;
  autoFillStatus?: AutoFillStatus;
  setAutoFillStatus: (status: AutoFillStatus | undefined) => void;
  hideTeam?: boolean;
  loadResponses: LoadResponses;
  staffDetails: StaffDetails[];
  reloadSubmission: () => void;
};

export const SubmissionForm = ({
  team,
  form,
  submission,
  aiUserFeedback,
  autoFillStatus,
  setAutoFillStatus,
  hideTeam,
  loadResponses,
  staffDetails,
  reloadSubmission,
}: SubmissionFormProps) => {
  const showConnectionBanner = useState(true);
  const uris = {
    teamUri: team.uri,
    submissionUri: submission.uri,
    formUri: form.uri,
  };
  const lookup = {
    addressLookup: useAddressLookup(),
    fileLookup: useFileLookup(uris),
  };
  const { post, result, ...connection } = intakeApi.submit();
  const { sendEvent } = useAnalytics();
  const { aiWordSupport } = useFeatureFlags();

  useAsync(async () => {
    if (!autoFillStatus && aiUserFeedback?.status === "generating") {
      setAutoFillStatus("filling");
      return;
    }
    if (autoFillStatus !== "filling" || aiUserFeedback?.status === "reviewed") {
      return;
    }

    if (aiUserFeedback?.status && aiUserFeedback.status !== "generating") {
      setAutoFillStatus("completed");
    } else {
      await delay(AI_FILL_POLLING_INTERVAL);
      reloadSubmission();
    }
  }, [autoFillStatus, aiUserFeedback, submission]);

  return (
    <>
      <Helmet>
        <title>{[team.name, form.title].filter(Boolean).join(" | ")}</title>
      </Helmet>
      <IntakeForm
        key={
          autoFillStatus === "filling"
            ? JSON.stringify(submission.responses)
            : undefined
        }
        connection={connection}
        form={{
          team,
          hideTeam,
          response: form.response,
          category: form.category,
          aiFillSettings: form.aiFillSettings,
        }}
        sections={form.sections}
        groups={form.groups}
        fields={form.fields}
        lookup={lookup}
        submission={submission}
        submitResponses={(input) => {
          if (input.status === "completed") {
            track("Submission Completed", {
              formUri: uris.formUri,
              submissionUri: uris.submissionUri,
            });
            sendEvent({
              name: "form_submission",
              params: {
                form_title: form.title,
              },
            });
          }

          return post({ body: { ...uris, ...input } });
        }}
        loadResponses={loadResponses}
        shouldLoadDBResponses
        postSubmissionStatus={result?.submissionStatus}
        staffDetails={staffDetails}
        showConnectionBanner={showConnectionBanner}
        autoFillStatus={autoFillStatus}
        runAutoFilling={async (uploadedFileKeys: string[]) => {
          await post({
            body: {
              ...uris,
              responses: {},
              aiFillFiles: uploadedFileKeys,
            },
          });
          setAutoFillStatus("filling");
        }}
        resetAutoFilling={() => setAutoFillStatus(undefined)}
        submitAutofillFeedback={async (feedback) => {
          await post({
            body: {
              ...uris,
              responses: {},
              autofillFeedback: feedback,
            },
          });
        }}
        aiWordSupport={aiWordSupport}
      />
    </>
  );
};
