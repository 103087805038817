import { AddressPrediction } from "@smart/bridge-resources-basic";
import {
  FieldType,
  LinkConditionType,
  MeetingType,
  NullableSmokeballAddress,
  ResponseSyncStatus,
  SubmissionStatus,
  SubmissionSyncStatus,
  TimeOfDay,
} from "@smart/bridge-types-basic";
import { UploadFn } from "@smart/itops-ui-dom";
import { TimeZone } from "@smart/itops-utils-basic";

export const sectionFallback = "Section Title";
export const groupFallback = "Group Title";
export const infoFallback = "Text";
export const summaryTitle = "Summary";
export const printLinkText = "Click here to save a copy of your form";
export const printTitle = "Intake Form";

export type FieldComponentType = FieldType | "mapped" | "group";

export const fieldFallbackLabel: Record<
  FieldComponentType | "section",
  string
> = {
  mapped: "Mapped",
  text: "Text",
  multilineText: "Multi-line text",
  choice: "Choice",
  date: "Date",
  currency: "Currency",
  address: "Address",
  phoneNumber: "Phone number",
  email: "Email",
  file: "File upload",
  info: "Text info",
  number: "Number",
  checkbox: "Multi-select",
  group: "Group of fields",
  yesNo: "Yes/No",
  section: "Section",
  appointment: "Appointment",
  payment: "Payment",
};

export type TeamItem = {
  name: string;
  picture?: string | null;
  email?: string | null;
  phone?: string;
  uri?: string;
};

export type ConnectionStatus = "initial" | "loading" | "error" | "success";

export type ConnectionItem = {
  status: ConnectionStatus;
  error?: string;
};

export type LinkItem = {
  condition: LinkConditionType;
  fieldUri: string;
  value: string;
  hide?: boolean | null;
};

export type SectionItem = {
  uri: string;
  title?: string | null;
  description?: string | null;
  hasRequired?: boolean;
  links?: LinkItem[] | null;
  validateOnDisplay?: boolean;
};

export type FieldError = {
  name: string;
  label?: string | null;
  index?: number;
  error?: string;
  fieldErrors?: undefined;
};

export type GroupError = {
  name: string;
  label?: string | null;
  index?: number;
  error?: string;
  fieldErrors: FieldError[];
};

export type SectionErrors = {
  total: number;
  errors: (FieldError | GroupError)[];
};

export type FieldItem<T extends FieldType = FieldType> = {
  uri: string;
  sectionUri: string;
  groupUri?: string | null;
  order: string;
  label?: string | null;
  description?: string | null;
  hint?: string | null;
  type: T;
  options?:
    | {
        value: string;
        label: string;
      }[]
    | null;
  allowCustomResponse?: boolean | null;
  mandatory?: boolean | null;
  links?: LinkItem[] | null;
  layout?: {
    id: string;
    providerId: string;
    name: string;
    parentId?: string | null;
    parentName?: string | null;
    parentProviderId?: string | null;
    displayName?: string | null;
  } | null;
  field?: {
    name: string;
  } | null;
  availableStaffIds?: string[] | null;
  duration?: number | null;
  availability?:
    | {
        day: number;
        enabled: boolean;
        fromTime: {
          hour: number;
          minute: number;
        };
        toTime: {
          hour: number;
          minute: number;
        };
      }[]
    | null;
  timezone?: string | null;
  bufferTime?: number | null;
  minimumNotice?: number | null;
  meetingType?: MeetingType | null;
};

// Support booking for a single staff/team member only.
export type SelectedAppointment = {
  selectedDate?: Date | null;
  selectedTeamMember?: {
    id: string;
    firstName?: string | null | undefined;
    middleName?: string | null | undefined;
    lastName: string;
  } | null;
  selectedTime?: TimeOfDay | null;
  timezone?: TimeZone;
};

export type GroupItem = {
  uri: string;
  sectionUri: string;
  order: string;
  description?: string | null;
  label?: string | null;
  hint?: string | null;
  repeatable?: boolean | null;
  minRepeat?: number | null;
  maxRepeat?: number | null;
  repeatPrompt?: string | null;
  links?: LinkItem[] | null;
};

export type SubmissionAutoFillStatus =
  | "completed"
  | "generated"
  | "generating"
  | "reviewed";

export type SubmissionItem = {
  uri: string;
  deleted?: boolean | null;
  responses?: Record<
    string,
    {
      value?: any;
      syncStatus?: ResponseSyncStatus;
    }
  >;
  status?: SubmissionStatus;
  autoFillStatus?: SubmissionAutoFillStatus;
  aiFillFromMatterInfo?: boolean | null;
  syncStatus?: SubmissionSyncStatus | null;
  lastUpdatedSectionUri?: string;
  updatedAt?: string;
};

export type OrderedGroup = {
  type: "group";
  order: string;
  group: GroupItem;
  fields: FieldItem[];
};

export type OrderedField = { type: "field"; order: string; field: FieldItem };

export type OrderedItem = OrderedField | OrderedGroup;

export type OrderedSection = {
  section: SectionItem;
  orderedItems: OrderedItem[];
};

export type ResponseSubmitter = (props: {
  responses: Record<string, any>;
  status?: SubmissionStatus;
  lastUpdatedSectionUri?: string;
  lastUpdatedFieldUri?: string;
}) => Promise<
  | {
      operationIds: string[];
      submissionStatus: { status?: SubmissionStatus; deleted?: boolean };
    }
  | undefined
>;

export type LookupOptions = {
  addressLookup: {
    search: ({
      search,
      sessionKey,
    }: {
      search: string;
      sessionKey?: string;
    }) => {
      result?: AddressPrediction[];
      loading?: boolean;
    };
    load: (props: {
      placeId: string;
      sessionKey?: string;
    }) => Promise<NullableSmokeballAddress | null | undefined>;
  };
  fileLookup: {
    load: ({
      fieldUri,
      fileNames,
    }: {
      fieldUri: string;
      fileNames: string[];
    }) => Promise<
      { key: string; downloadUrl: string; uploadUrl: string }[] | undefined
    >;
    upload: UploadFn;
  };
};

export type SubmissionStatusItem = {
  status?: SubmissionStatus;
  deleted?: boolean;
};

export type IntakeFormSectionRef = {
  submitForm: () => void;
  errors: SectionErrors | undefined;
  connection?: ConnectionItem;
};

export type LoadResponses = () => Promise<
  Record<string, { value?: any; updatedAt: string }> | undefined
>;

export const appointmentDurations = [15, 30, 45, 60];
export const bufferTimes = [15, 30, 45, 60];
export const minimumNoticesInMinute = [60, 240, 480, 1440]; // [1hr, 4hr, 8hr, 1day]

export enum WeekDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
export const calendarWeek = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday,
];
export const calendarWeekLabel: Record<WeekDay, string> = {
  [WeekDay.Monday]: "Monday",
  [WeekDay.Tuesday]: "Tuesday",
  [WeekDay.Wednesday]: "Wednesday",
  [WeekDay.Thursday]: "Thursday",
  [WeekDay.Friday]: "Friday",
  [WeekDay.Saturday]: "Saturday",
  [WeekDay.Sunday]: "Sunday",
};

export const calendarWeekShortLabel: Record<WeekDay, string> = {
  [WeekDay.Monday]: "Mo",
  [WeekDay.Tuesday]: "Tu",
  [WeekDay.Wednesday]: "We",
  [WeekDay.Thursday]: "Th",
  [WeekDay.Friday]: "Fr",
  [WeekDay.Saturday]: "Sa",
  [WeekDay.Sunday]: "Su",
};

export const minTimeOfDay: TimeOfDay = { hour: 6, minute: 0 };
export const maxTimeOfDay: TimeOfDay = { hour: 20, minute: 0 };

export const MAX_MINS_IN_PAST = 2;

export const calendarMonthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const calendarWeekDays = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday,
];

export type AutoFillStatus = "filling" | "completed" | "failed";

export type ToastContent = {
  text: string;
  type: "success" | "error";
};

export const betaColour = "rgb(235,46,109)";
